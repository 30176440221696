<template>
	<div class="notice">
		<div style="display: flex;justify-content: space-between;">
			<div class="header">
				<div>赛事管理></div>
				<div class="current">赛事查询</div>
			</div>
			<div style="display: flex;">
				<div class="input">
					<el-input class="input-with-select" placeholder="请输入赛事名称" v-model="info.matchName"
						style="font-size: 15PX;">
						<el-button slot="append" @click="searchfor" style="font-size: 17PX;">搜索</el-button>
					</el-input>
				</div>
				<div class="buttom" @click="registration">
					<img class="img" src="../assets/image/registration.png" alt="">
					<div class="title">赛事登记</div>
				</div>
				<div class="buttomdow" @click="download">
					<div class="titledow">赛事登记操作手册</div>
				</div>
			</div>
		</div>

		<div class="screen-box">
			筛选赛事：
			<el-link type="primary" :underline="false" @click="toggleFilterOptions" v-if="!showFilterOptions">展开 <i
					class="el-icon-arrow-down el-icon--right"></i> </el-link>
			<el-link type="primary" :underline="false" @click="toggleFilterOptions" v-else>收起 <i
					class="el-icon-arrow-up el-icon--right"></i> </el-link>
		</div>
		<div v-if="showFilterOptions">
			<div class="themoon-box">
				<div class="title">年份：</div>
				<div :class="yearindex == '' ? 'name selected' : 'name'" @click="year('')">全部</div>
				<img src="../assets/image/zuo.png" alt="" class="zuo" @click="getzuo">
				<div v-for="(item, index) in time" :key="index"
					:class="yearindex == index + 1 ? 'name selected' : 'name'" @click="year(index + 1, item)">{{ item }}
				</div>
				<img src="../assets/image/you.png" alt="" class="you" @click="getyou">
			</div>
			<div class="themoon-box">
				<div class="title">月份：</div>
				<div :class="themoonindex == '' ? 'name selected' : 'name'" @click="themoon('')">全部</div>
				<div v-for="(item, index) in month" :key="index"
					:class="themoonindex == index + 1 ? 'name selected' : 'name'"
					@click="themoon(index + 1, item.value)">{{ item.name }}</div>
			</div>
			<!-- <div class="themoon-box">
				<div class="title">性质：</div>
				<div :class="natureindex == '' ?'name selected':'name'" @click="nature('')">全部</div>
				<div v-for="(item,index) in natureList" :key="index" :class="natureindex == index+1 ?'name selected':'name'"
					@click="nature(index + 1,item.itemValue)">{{item.itemText}}</div>
			</div> -->
			<div class="themoon-box">
				<div class="title">级别：</div>
				<div :class="levelindex == '' ? 'name selected' : 'name'" @click="level('')">全部</div>
				<div v-for="(item, index) in eventList" :key="index"
					:class="levelindex == index + 1 ? 'name selected' : 'name'"
					@click="level(index + 1, item.itemValue)">
					{{ item.itemText }}</div>
			</div>
			<!-- 赛事地点 -->
			<div class="themoon-boxs">
				<div class="title">地点：</div>
				<div :class="qyindex == '' ? 'name selected' : 'name'" @click="matchAddress('')">全部</div>
				<img src="../assets/image/zuo.png" alt="" class="zuo" @click="getzuos">
				<div class="con">
					<div v-for="(item, index) in matchAddresslist" :key="index"
						:class="qyindex == index + 1 ? 'name selected' : 'name'"
						@click="matchAddress(index + 1, item.itemValue)">
						{{ item.itemText }}
					</div>
				</div>
				<img src="../assets/image/you.png" alt="" class="you" @click="getyous">

			</div>

			<div class="column">
				<div class="name" @click="column(1, '1784398050100674562', 'summer_sports')"
					:style="{ 'background': columnIndex == 1 ? '#164B92' : '#ECF1FD', 'color': columnIndex == 1 ? '#FFFFFF' : '#3C75C2' }">
					夏季奥运项目
				</div>
				<div class="name" @click="column(2, '1784475618153758722', 'winter_sports')"
					:style="{ 'background': columnIndex == 2 ? '#164B92' : '#ECF1FD', 'color': columnIndex == 2 ? '#FFFFFF' : '#3C75C2' }">
					冬季奥运项目
				</div>
				<div class="name" @click="column(3, '1784478645149401089', 'non_austrian_sports')"
					:style="{ 'background': columnIndex == 3 ? '#164B92' : '#ECF1FD', 'color': columnIndex == 3 ? '#FFFFFF' : '#3C75C2' }">
					非奥运项目
				</div>
			</div>

			<div class="projectList" v-if="columnIndex !=0 ">
				<div class="title" @click="getsports('', '')"
					:style="{ 'color': sportsindex == '' ? '#3C75C2' : '#000' }">全部
				</div>
				<div class="title" v-for="(item, index) in sportsList" :key="index" @click="getsports(index + 1, item)"
					:style="{ 'color': sportsindex == index + 1 ? '#3C75C2' : '#000' }">{{ item.item_text }}</div>
			</div>
		</div>

		<div class="course-box">
			<div class="course" v-for="(item, index) in data" :key="index" @click="getdetails(item)">
				<div class="introduction">
					<img class="img" :src="item.image" alt="">
				</div>
				<div class="name-container">
					<div class="name">{{ item.matchName }}</div>
				</div>
				<div class="line" style="margin: 0;"></div>
				<div class="specific-box">
					<div class="specific">
						<img class="img" src="../assets/image/date3.png" alt="">
						<div class="information">
							<div class="information-title">比赛时间</div>
							<div class="information-date" v-if="item.matchDateStart == item.matchDateEnd">{{
						item.matchDateStart }}</div>
							<div class="information-date" v-else>{{ item.matchDateStart }} 至 {{ item.matchDateEnd }}
							</div>
						</div>
					</div>
					<div class="line"></div>
					<div class="specific">
						<img class="img" src="../assets/image/addes3.png" alt="">
						<div class="information">
							<div class="information-title">比赛地点</div>
							<div class="information-date">{{ item.matchAddressStadium }}
							</div>
						</div>
					</div>
					<div class="line"></div>
					<div class="specific">
						<img class="img" src="../assets/image/status.png" alt="">
						<div class="information">
							<div class="information-title">赛事状态</div>
							<div class="information-date">{{ item.matchRunStatus }}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="paging">
			<pagination :total="total" @currentchange="currentchange" :go="12" :pageSize="12" :pageNo="info.pageNo"></pagination>
		</div>
	</div>
</template>
<script>
export default {
	name: '',
	components: {},
	data() {
		return {
			yearindex: '',
			themoonindex: '',
			natureindex: '',
			levelindex: '',
			qyindex: '',
			columnIndex: 0,
			natureList: [],
			eventList: [{
				itemText: '国际体育赛事',
				itemValue: 4
			},
			{
				itemText: '全国体育赛事',
				itemValue: 3
			},
			{
				itemText: '跨省市体育赛事',
				itemValue: 2
			},
			{
				itemText: '市级体育赛事',
				itemValue: 1
			},
			{
				itemText: '区级体育赛事 ',
				itemValue: 0
			}
			],
			month: [{
				name: '一月',
				value: '01'
			},
			{
				name: '二月',
				value: '02'
			},
			{
				name: '三月',
				value: '03'
			},
			{
				name: '四月',
				value: '04',
			},
			{
				name: '五月',
				value: '05'
			},
			{
				name: '六月',
				value: '06'
			},
			{
				name: '七月',
				value: '07'
			},
			{
				name: '八月',
				value: '08'
			},
			{
				name: '九月',
				value: '09'
			},
			{
				name: '十月',
				value: '10'
			},
			{
				name: '十一月',
				value: '11'
			},
			{
				name: '十二月',
				value: '12'
			}
			],
			matchAddresslist:[],
			time: [],
			info: {
				pageNo: 1,
				pageSize: 12,
				matchYear: '',
				matchMonth: '',
				matchProperty: '',
				matchLevel: '',
				matchSport: '',
				matchName: '',
				matchSportCollection: '',
				matchAddressDistrict:''
			},
			total: 0,
			data: [],
			columnId: '',
			columnPid: '',
			sportsList: [],
			sportsindex: 0,
			showFilterOptions: false,
			cstime:'',
			shiftList:[]
		}
	},
	created() {
		this.info.matchName = this.$route.query.matchName || ''
		this.yearList()
		this.columnId = this.$route.query.id
		this.columnPid = this.$route.query.pid
		// this.getRefereeSports()
		this.natureOfTheCompetition()
		this.getMatchBasicInfo()
		this.areaList()

	},
	methods: {
		areaList() {
			this.$api.areaList().then(res => {
				console.log(re,'ressssss');
				this.matchAddresslist = res.data.data.result
		        this.copyList = JSON.parse(JSON.stringify(this.matchAddresslist))
			})
		},
		yearList() {
			this.$api.yearList(this.info).then(res => {
				this.cstime = res.data.data[0]
				this.time = res.data.data
			})
		},
		getyous(){
			if(this.matchAddresslist.length>9){
				this.matchAddresslist.shift()
				this.qyindex-=1;
			    // this.info.matchAddressDistrict = ''
				// this.getMatchBasicInfo();
			}
		},
		getzuos(){
			if(this.matchAddresslist.length!=17){
				this.matchAddresslist.unshift(this.copyList[17-(this.matchAddresslist.length+1)])
				this.qyindex +=1;
				// this.info.matchAddressDistrict = ''
				// this.getMatchBasicInfo();
			}
		},
		getzuo() {
			this.yearindex = '';
			this.info.matchYear = '';
			this.getMatchBasicInfo();
			let time = parseInt(this.time[0])
			if (time < parseInt(this.cstime) + 5) {
				this.time = this.time.map(year => {
					let newYear = parseInt(year) + 1;
					if (newYear <= parseInt(this.cstime) + 5) {
						return newYear.toString();
					} else {
						return year;
					}
				});
			}
		},
		getyou() {
			this.yearindex = '';
			this.info.matchYear = '';
			this.getMatchBasicInfo();
			let time = parseInt(this.time[this.time.length - 1])
			if (time != 2015) {
				this.time = this.time.map(year => {
					let newYear = parseInt(year) - 1;
					if (newYear >= 2015) {
						return newYear.toString();
					} else {
						return year;
					}
				});
			}
		},
		currentchange(val) {
			this.info.pageNo = val
			this.getMatchBasicInfo()
		},
		year(index, row) {
			this.yearindex = index
			this.info.matchYear = row
			this.info.pageNo = 1
			this.getMatchBasicInfo()
		},
		themoon(index, row) {
			this.themoonindex = index
			this.info.matchMonth = row
			this.info.pageNo = 1
			this.getMatchBasicInfo()
		},
		//区域地点
		matchAddress(index, row){
            this.qyindex = index
			this.info.matchAddressDistrict = row
			this.info.pageNo = 1
			this.getMatchBasicInfo()
		},
		nature(index, row) {
			this.natureindex = index
			this.info.matchProperty = row
			this.info.pageNo = 1
			this.getMatchBasicInfo()
		},
		level(index, row) {
			this.levelindex = index
			this.info.matchLevel = row
			this.info.pageNo = 1
			this.getMatchBasicInfo()
		},
		getRefereeSports() {
			this.$api.getRefereeSports({
				dictId: '1663440116581470209'
			}).then(res => {
				this.sportsList = res.data.data.result
			})
		},
		column(e, id, data) {
			this.columnIndex = e
			this.sportsindex = 0
			this.info.matchSport = ''
			this.info.pageNo = 1
			this.info.matchSportCollection = data
			this.getMatchBasicInfo()
			this.$api.getRefereeSportsDictItem({
				dictId: id
			}).then(res => {
				this.sportsList = res.data.data.result
			})
		},
		getsports(index, item) {
			this.sportsindex = index
			this.info.matchSport = item.item_value
			this.info.pageNo = 1
			this.getMatchBasicInfo()
		},
		natureOfTheCompetition() {
			this.$api.natureOfTheCompetition().then(res => {
				this.natureList = res.data.data.result
			})
		},
		searchfor() {
			this.info.pageNo = 1
			this.getMatchBasicInfo()
		},
		getMatchBasicInfo() {
			this.$api.getMatchBasicInfo(this.info).then(res => {
				res.data.data.result.records.forEach(item => {
					item.matchAddressStadium = item.addresses.map(item => {
						return item.matchAddressStadium
					}).join('、')
					this.$api.getFile({objectName: item.imgs[0]}).then(res => {
						this.$convert(res.data.data.result.url).then((fileUrl) => {
							item.image = fileUrl
							this.$forceUpdate()
						})
					})
				})
				this.data = res.data.data.result.records
				this.total = res.data.data.result.total
			})
		},
		getdetails(row) {
			this.$router.push({
				path: '/details',
				query: {
					id: row.id,
					refresh: new Date().getTime(),
					type: 1,
					columnId: this.columnId,
					columnPid: this.columnPid
				}
			});
		},
		registration() {
			if (this.$store.state.token == '') {
				this.$store.commit("EXIT_TOKEN")
				this.$router.push({
					path: '/login',
					query: {
						person: false,
						grp: true,
						refresh: new Date().getTime()
					}
				})
				return
			}
			if (this.$store.state.userType == 'user_type_01') {
				this.$confirm('当前账号为个人账号，是否切换成团体账号', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$store.commit("EXIT_TOKEN")
					this.$router.push({
						path: '/login',
						query: {
							person: false,
							grp: true,
							refresh: new Date().getTime()
						}
					})
				}).catch(() => { });
				return
			}
			this.$api.getTimeStamp().then(ess => {
				let data = {
					accessToken: this.$store.state.token,
					redirectURL: '/authIndex',
					timestampe: ess.data.data.timestamp
				}
				this.$api.getCode(data).then(err => {
					let url = process.env.VUE_APP_BASE_API_ADMIN + '/tokenLogin?code=' + err.data.data
						.result.code
					window.open(url, '_self');
				})
			})
		},
		toggleFilterOptions() {
			this.showFilterOptions = !this.showFilterOptions;
		},
		download() {
			const a = document.createElement('a')
			a.href = '/static/赛事登记操作手册.pdf'
			a.download = '赛事登记操作手册.pdf'
			a.click()
		},
	}
}
</script>

<style lang="scss" scoped>
.notice {
	width: 1200PX;
	margin: 0 auto;
}

.header {
	display: flex;
	margin-top: 34PX;
	margin-left: 20PX;
	font-size: 18PX;
	font-weight: 500;
	color: #333333;
	cursor: pointer;
}

.current {
	color: #0066FF;
}

.input {
	margin-top: 28PX;
	height: 39PX;
}

.input ::v-deep .el-input__inner {
	height: 34PX;
	border: 2PX solid #164B92;
	height: 39PX;
	width: 160PX;
}

.input ::v-deep .el-input-group__append {
	border: 2PX solid #164B92;
	color: #fff;
	background-color: #164B92;
	width: 10PX;
	height: 39PX;
}

.input ::v-deep .el-input-group__append button.el-button {
	font-weight: bold;
}

.buttom {
	display: flex;
	width: 149PX;
	height: 39PX;
	background: #D42C26;
	border-radius: 7PX;
	font-size: 15PX;
	font-weight: 400;
	color: #FFFFFF;
	line-height: 39PX;
	margin-left: 30PX;
	margin-top: 28PX;
	cursor: pointer;
}

.buttom .img {
	width: 21PX;
	height: 21PX;
	margin-top: 10PX;
	margin-left: 28PX;
	margin-right: 9PX;
}

.buttomdow {
	margin-left: 30PX;
	text-decoration: underline;
	cursor: pointer;
	margin-top: 28PX;
	display: flex;
	align-items: center;
	color: #000;
}

.screen-box {
	margin-top: 26PX;
	font-size: 18PX;
	font-weight: 400;
	margin-left: 20PX;
	color: #333333;
	display: flex;
}

.screen-box .screen {
	display: flex;
}

.screen-box .screen .title {
	margin-left: 20PX;
}


.themoon-boxs {
	width: 1200PX;
	height: 41PX;
	background: rgba(212, 212, 212, 0.15);
	border-radius: 12PX;
	margin-top: 20PX;
	display: flex;
	// overflow-x: auto;
	// overflow: hidden;
	align-items: center;
}
.themoon-boxs .con{
	display: flex;
	align-items: center;
	overflow: hidden;
}

.themoon-boxs .title {
	font-size: 16PX;
	flex: 0 0 auto;
	font-weight: bold;
	color: #333333;
	margin-left: 34PX;
	cursor: pointer;
	padding: 0 20PX;
}

.themoon-boxs .name {
	flex: 0 0 auto;
	font-size: 16PX;
	font-weight: 400;
	color: #333333;
	cursor: pointer;
	padding: 0 20PX;
}

.themoon-box {
	width: 1200PX;
	height: 41PX;
	background: rgba(212, 212, 212, 0.15);
	border-radius: 12PX;
	margin-top: 20PX;
	display: flex;
	align-items: center;
}

.themoon-box .title {
	font-size: 16PX;
	font-weight: bold;
	color: #333333;
	margin-left: 34PX;
	cursor: pointer;
	padding: 0 20PX;
}

.themoon-box .name {
	font-size: 16PX;
	font-weight: 400;
	color: #333333;
	cursor: pointer;
	padding: 0 20PX;
}

.selected {
	color: #164B92 !important;
	height: 41PX;
	padding: 0 20PX;
	background: #ECF1FD;
	border-radius: 6PX;
	display: flex;
	align-items: center;
}

.column {
	margin-top: 30PX;
	display: flex;
	justify-content: center;
	border-bottom: 2PX solid #164B92;
	cursor: pointer;
}

.column .name {
	width: 317PX;
	height: 50PX;
	background: #164B92;
	border-radius: 20PX 20PX 0PX 0PX;
	font-size: 20PX;
	font-weight: bold;
	color: #FFFFFF;
	text-align: center;
	line-height: 50PX;
	margin-right: 13PX;
}

.projectList {
	display: flex;
	margin: 0 7PX;
	flex-wrap: wrap;
	justify-content: flex-start;
}

.projectList .title {
	font-size: 16PX;
	font-weight: 400;
	color: #333333;
	margin-top: 35PX;
	margin-right: 30PX;
	cursor: pointer;
}

.course-box {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	margin-top: 30PX;
	cursor: pointer;
}

.course {
	width: 266PX;
	height: 324PX;
	border-radius: 17PX;
	margin-bottom: 55PX;
	margin-right: 45PX;
	cursor: pointer;
}

.course:nth-child(4n) {
	margin-right: 0;
}

.introduction {
	cursor: pointer;
}

.introduction img {
	width: 266PX;
	height: 160PX;
	border-radius: 17PX 17PX 0PX 0PX;

}

.name-container {
	width: 266PX;
	height: 50PX;
	background: #F1F8FF;
	font-size: 16PX;
	font-weight: bold;
	color: #164B92;
	overflow: hidden;
	display: flex;
	align-items: center;
}

.name-container .name {
	width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	padding: 0 10PX;
}

.specific-box {
	width: 266PX;
	height: 132PX;
	background: #F1F8FF;
	border-bottom-left-radius: 17PX;
	border-bottom-right-radius: 17PX;
	z-index: 999;
	position: relative;
	cursor: pointer;
}

.specific-box .specific {
	display: flex;
	padding: 3PX 0;
}

.specific-box .specific .img {
	width: 31PX;
	height: 29PX;
	margin-top: 4PX;
	margin-left: 26PX;
}

.specific-box .specific .information {
	margin-left: 11PX;
}

.specific-box .specific .information .information-title {
	font-size: 14PX;
	font-weight: bold;
	color: #164B92;
	margin-top: 1PX;
}

.specific-box .specific .information .information-date {
	font-size: 12PX;
	font-weight: 500;
	color: #333333;
	margin-top: 1PX;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	-o-text-overflow: ellipsis;
	width: 175PX;
}

.specific-box .button {
	width: 181PX;
	height: 36PX;
	background: #0A6DF3;
	border-radius: 18PX;
	font-size: 15PX;
	font-weight: bold;
	color: #FFFFFF;
	line-height: 36PX;
	text-align: center;
	margin-left: 42PX;
	margin-top: 15PX;

}

.line {
	width: 262PX;
	height: 1PX;
	background: #DADADB;
	opacity: 0.63;
}

.paging {
	margin-top: 18PX;
	margin-bottom: 104PX;
	display: flex;
	align-items: center;
}

.paging span {
	margin-right: 5PX;
}

.el-link.el-link--primary {
	color: #0066FF;

}

.el-link {
	font-size: 15PX;
}

.zuo {
	width: 25PX;
	margin: 10PX;
	cursor: pointer;
}

.you {
	width: 25PX;
	margin: 10PX;
	cursor: pointer;
}
</style>
